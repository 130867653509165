
<template>
  <vx-card>
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup classContent="popup-example label-font" title="ลบข้อมูล" :active.sync="removePopupActive">
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12"
            class="form-label px-2" style="padding-left: 20px 30px;">ลบ {{removeInfo}}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
            <vs-button @click="remove()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button @click="closeRemovePanel()" style="margin:10px;" color="danger" type="filled">ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- UPDATE POP UP -->
    <template>
      <vs-popup classContent="popup-example label-font" title="อัพเดตข้อมูล" :active.sync="updateBranchPopupActive1">
        <!-- ชื่อสาขา  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">ชื่อสาขา</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="branchName" v-model="updateItem.branchName" />
          </vs-col>
        </vs-row>

        <!--  ที่อยู่  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">ที่อยู่</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-textarea class="w-full" name="branchAddress" v-model="updateItem.address"
              style="border: 1px #cccccc solid; height: 100px;" />
          </vs-col>
        </vs-row>

        <!-- เวลาทำการ  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">เวลาทำการ</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="workingTime" v-model="updateItem.workingTime" />
          </vs-col>
        </vs-row>
        <!-- เบอร์ติดต่อ  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">เบอร์ติดต่อ</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="branchPhone" v-model="updateItem.phone" />
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
            <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button @click="closeUpdateBranchPanel()" style="margin:10px;" color="danger" type="filled">ยกเลิก
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- CREATE POP UP -->
    <template>
      <vs-popup classContent="popup-example label-font" title="เพิ่มข้อมูล" :active.sync="createBranchPopupActive1">
        <!-- ชื่อสาขา  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">ชื่อสาขา</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="branchName" v-model="branchName1" />
          </vs-col>
        </vs-row>

        <!--  ที่อยู่  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">ที่อยู่</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-textarea class="w-full" name="branchAddress" v-model="branchAddress1"
              style="border: 1px #cccccc solid; height: 100px;" />
          </vs-col>
        </vs-row>

        <!-- เวลาทำการ  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">เวลาทำการ</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="workingTime" v-model="workingTime1" />
          </vs-col>
        </vs-row>
        <!-- เบอร์ติดต่อ  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3"
            class="form-label px-2">เบอร์ติดต่อ</vs-col>
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7"
            class="form-label px-2">
            <vs-input class="w-full" name="branchPhone" v-model="branchPhone1" />
          </vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
            <vs-button @click="create()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button @click="closeCreateBranchPanel()" style="margin:10px;" color="danger" type="filled">ยกเลิก
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <div class="grid-layout-container alignment-block" style="vertical-align:text-top ;width:100%;">
      <!--------------------------------------------------------------------->
      <!--  theline  -->
      <vs-row class="row-form" vs-w="12" style="margin-top:30px;">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4">
          <h3 style="margin-bottom: 20px;">{{labelname}}</h3>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4"></vs-col>

        <!-- SAVE ITEM -->
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="2"></vs-col>
        <!-- SAVE ITEM -->
        <!-- BACK to STOCK -->
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="2">
          <vs-button v-if="getCurrentPackage !== 's'" vs-type="flex" vs-align="center" vs-justify="center"
            style="width:100%; padding-left:0; padding-right:0;" color="#7ED321" type="filled"
            @click="openCreatePanel()">
            <span>
              <i style="font-size:18px; margin-right:5px;vertical-align:middle;"
                class="material-icons">add_circle_outline</i>
            </span>
            <span style="vertical-align:middle;">เพิ่มสาขา</span>
          </vs-button>
        </vs-col>
        <!-- BACK to STOCK -->
      </vs-row>
      <!-- <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-lg="12"
          vs-sm="12"
          vs-xs="12"
          class="form-label px-2"
        >
          <div style="width:100%; height:5px; background-color:#50E3C2;"></div>
        </vs-col>
      </vs-row>-->
      <!--------------------------------------------------------------------->
    </div>
    <!-- HEADER -->

    <vs-table pagination max-items="20" search :data="configlist">
      <template slot="thead">
        <!-- <vs-th sort-key="code">รหัสสาขา</vs-th> -->
        <vs-th sort-key="branchName">ชื่อสาขา</vs-th>
        <vs-th>รายละเอียดสาขา</vs-th>
        <vs-th>อัพเดต</vs-th>
        <vs-th sort-key="state">สถานะ</vs-th>
        <vs-th style="text-align:end;">การจัดการ</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td style="width:10% ; padding-left:20px;">{{ tr["code"] }}</vs-td> -->

          <vs-td style="width:15% ">{{ tr.branchName }}</vs-td>
          <vs-td style="width:30% ; padding-left:20px;">
            <b>ที่อยู่ :</b>
            {{ tr.address }}
            <br />
            <b>เวลาทำการ :</b>
            {{ tr.workingTime }}
            <br />
            <b>เบอร์ติดต่อ :</b>
            {{ tr.phone }}
          </vs-td>
          <vs-td style="width:15% ; padding-left:20px;">{{ formatDate(tr.updateDateTime) }}</vs-td>
          <vs-td style="width:15% ; padding-left:20px;">
            <vs-switch v-if="tr.flag1 !== 'm'" @click="changeState(tr['branchId'], indextr)" color="success"
              v-model="tr.state">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-td>
          <vs-td style="width:15% text-align:right;">
            <i @click="openUpdatePanel(tr, indextr)"
              style="cursor:pointer; color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons">edit</i>
            <i v-if="tr.flag1 !== 'm'" @click="openRemovePanel(tr['branchId'], indextr)"
              style="cursor:pointer; color:#F63B39; font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons">delete_forever</i>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </vx-card>
</template>

<script>
import shapeFormat from "../../../mixins/shapeFormat.js";
export default {
  props: {
    // configlist: Array,
    apiname: String,
    idname: String,
    labelname: String
  },
  mixins: [shapeFormat],
  data() {
    return {
      updateItem: {
        branchName: "",
        flag1:"",
        address: "",
        workingTime: "",
        phone: ""
      },
      updateBranchPopupActive1: false,
      createBranchPopupActive1: false,
      branchName1: "",
      branchAddress1: "",
      workingTime1: "",
      branchPhone1: "",
      currentUser: "guest",
      branchName: "",
      theInfo: "",
      theState: true,
      theIndex: "",
      theID: "",
      configitem: "",
      dec: "",
      createPopupActive: false,
      updatePopupActive: false,
      removePopupActive: false,
      selected: [],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      configlist: []
    };
  },
    computed: {
  //  *** BEGIN : 2020-12-12 : Search By branches
  getCurrentPackage(){

      return this.$store.getters["auth/currentPackage"];
    }

  },
  methods: {
    reload() {
      /*****  BEGIN: AXIOS HTTP   *****/
      this.$http
        .get(
          this.$store.state.apiURL + "/api/" + this.apiname,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.configlist = response.data;
            // console.log("CREATED : this.configlist >>>> ", this.configlist);
          } else {
            this.$vs.notify({
              title: "ไม่สามารถโหลดข้อมูลได้",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "ไม่สามารถโหลดข้อมูลได้",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    openCreatePanel() {
      if(  this.getCurrentPackage === 's' && this.configlist.length >= 1 )
      {
        this.$swal({
          icon: 'warning',
          title: 'Package s ไม่สามารถสร้างสาขาใหม่ได้',
        });
        this.closeCreateBranchPanel();
        return;
      }

      if(  this.getCurrentPackage === 'm' && this.configlist.length >= 2 )
      {
          this.$swal({
          icon: 'warning',
          title: 'Package m <br>สร้างสาขาใหม่ได้ไม่เกิน 2 ร้าน',
        });
        return;
      }
      this.createBranchPopupActive1 = true;

      this.resetValue();
    },
    closeCreateBranchPanel() {
      this.createBranchPopupActive1 = false;
      this.resetValue();
    },

    // branchName1: "",
    //   branchAddress1: "",
    //   workingTime1: "",
    //   branchPhone1: "",
    create() {
      if(  this.getCurrentPackage === 's' && this.configlist.length >= 1 )
      {
        this.$swal({
          icon: 'warning',
          title: 'Package s ไม่สามารถสร้างสาขาใหม่ได้',
        });
        this.closeCreateBranchPanel();
        return;
      }

      if(  this.getCurrentPackage === 'm' && this.configlist.length >= 2 )
      {
          this.$swal({
          icon: 'warning',
          title: 'Package m <br>สร้างสาขาใหม่ได้ไม่เกิน 2 ร้าน',
        });
        return;
      }

      var item = {
        branchName: this.branchName1,
        address: this.branchAddress1,
        workingTime: this.workingTime1,
        phone: this.branchPhone1,

        state: true,
        createBy: this.currentUser,
        updateBy: this.currentUser
      };
      /*****  BEGIN: AXIOS HTTP   *****/
      this.$http
        .post(
          this.$store.state.apiURL + "/api/" + this.apiname,
          item,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "บันทึกสำเร็จ",
              position: "top-center",
              text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("200 >>> ", response.data);
            var newobj = response.data;
            item[this.idname] = newobj[this.idname];
            this.configlist.push(item);
            this.reload();
            this.createBranchPopupActive1 = false;
            window.location.href = "/backoffice/shop-info";
          } else {
            // console.log("NOT 200 >>> ");
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    resetValue() {
      this.configitem = "";
      this.dec = "";
      this.theID = "";
      this.theState = true;
      this.updateItem = {
        branchName: "",
        flag1:"",
        address: "",
        workingTime: "",
        phone: ""
      };
    },

    openUpdatePanel(item, index) {

      // console.log("removing branch >>> ", item);

      this.updateBranchPopupActive1 = true;
      this.theIndex = index;
      this.updateItem = {
        branchId: item.branchId,
        code: item.code,

        branchName: item.branchName,
        address: item.address,
        workingTime: item.workingTime,
        phone: item.phone,
        flag1: item.flag1,
        flag2: item.flag2,

        state: item.state,
        createBy: item.currentUser,
        updateBy: item.currentUser
      };


    },
    openRemovePanel(id, index) {
      this.removePopupActive = true;
      this.theID = id;
      this.theIndex = index;

      console.log("removing branch id >>> ", id);

      var item = this.configlist.find(x => x["branchId"] === this.theID);
      this.removeInfo = item.branchName;

      console.log("removing branch removeInfo >>> ", this.removeInfo);

      if (item != null) {
        this.theInfo = item.code;
        // console.log("ITEM IS EXISTING");
      }
    },

    closeUpdateBranchPanel() {
      this.updateBranchPopupActive1 = false;
      this.resetValue();
    },
    changeState(id, index) {
      // console.log("when change state idname >>>> ", this.idname);

      this.theID = id;
      this.theIndex = index;
      // this.configlist[this.theIndex].state = !this.configlist[this.theIndex]
      //   .state;
      this.theState = !this.configlist[this.theIndex].state;

      // console.log(
      //   "this.theID >>> ",
      //   this.theID,
      //   " >>> this.configlist[this.theIndex].state >>> ",
      //   this.configlist[this.theIndex].state
      // );

      /*****  BEGIN: AXIOS HTTP   *****/

      this.$http
        .put(
          this.$store.state.apiURL +
            "/api/" +
            this.apiname +
            "/changestate/" +
            this.theID,
          {
            state: this.theState
          },
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log("CHANGE STATUS");
          if (response.status === 200) {
            // this.$vs.notify({
            //   title: "บันทึกสำเร็จ",
            //   position: "top-center",
            //   text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
            //   iconPack: "feather",
            //   icon: "icon-alert-circle",
            //   color: "success"
            // });
            this.reload();
          } else if (response.status === 500) {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });

      /*****  END: AXIOS HTTP   *****/
    },
    update() {


      /*****  BEGIN: AXIOS HTTP   *****/

      var upitem = this.updateItem;
      upitem.CreateBy = this.currentUser;
      upitem.UpdateBy = this.currentUser;

      this.$http
        .put(
          this.$store.state.apiURL +
            "/api/" +
            this.apiname +
            "/" +
            this.updateItem.branchId,
          upitem,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "บันทึกสำเร็จ",
              position: "top-center",
              text: "ได้บันทึกข้อมูลสินค้าเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });


            this.reload();
            // console.log("response.data ", response.data);
            this.updateBranchPopupActive1 = false;
             window.location.href = "/backoffice/shop-info";

          } else {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          // console.log("เกิดข้อผิดลาด");

          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
      /*****  END: AXIOS HTTP   *****/
    },
    closeRemovePanel() {
      this.removePopupActive = false;
    },
    remove() {
      this.$http
        .delete(
          this.$store.state.apiURL + "/api/" + this.apiname + "/" + this.theID,
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "ลบสำเร็จ",
              position: "top-center",
              text: "ได้ลบข้อมูลเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success"
            });
            // console.log("200 :: response.data  ");
            this.removePopupActive = false;
            this.configlist.splice(this.theIndex, 1);
            window.location.href = "/backoffice/shop-info";
          } else {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาดในการทำงาน",
              position: "top-center",
              text: "เกิดข้อผิดพลาดในการทำงาน",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "เกิดข้อผิดพลาดในการทำงาน",
            position: "top-center",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
          // console.log("CATCH ERROR :: response.data  ");
        });
    }
  },
  mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("username"));
  },
  created() {
    /*****  BEGIN: AXIOS HTTP   *****/
    this.reload();
    // this.$http
    //   .get(this.$store.state.apiURL + "/api/" + this.apiname,this.$store.state.auth.apiHeader)
    //   .then(response => {
    //     console.log(response.status);
    //     if (response.status === 200) {
    //       this.configlist = response.data;
    //       console.log("CREATED : response.data >>>> ", response.data);
    //     } else {
    //       this.$vs.notify({
    //         title: "ไม่สามารถโหลดข้อมูลได้",
    //         position: "top-center",
    //         text: "เกิดข้อผิดพลาดในการทำงาน",
    //         iconPack: "feather",
    //         icon: "icon-alert-circle",
    //         color: "danger"
    //       });
    //     }
    //   })
    //   .catch(error => {
    //     this.$vs.notify({
    //       title: "ไม่สามารถโหลดข้อมูลได้",
    //       position: "top-center",
    //       text: error.message,
    //       iconPack: "feather",
    //       icon: "icon-alert-circle",
    //       color: "danger"
    //     });
    //   });
    /*****  END: AXIOS HTTP   *****/
  }
};
</script>

<style>
.vs-component .vs-switch .vs-switch-success .vs-switch-active {
  width: 50px !important;
}
</style>

<template lang="pug">
div
      // General product group
      vs-row(vs-w='12')
        template(v-if="inputImage")
          .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-12(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
            img.pb-6.responsive(:src="baseImagePath(inputImage)" alt="img" style="width: 70%")
            i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel
        template(v-else)
          template(v-if="dataImg")
            // Image Container
            .img-container.w-64.mx-auto.flex.items-center.justify-center(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
              img.responsive(:src="dataImg" alt="img" style="width: 70%")
              i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;" @click="removeItemImage()" ) cancel

          // Image upload Buttons
          vs-col.px-6.pb-6.pt-6( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            .upload-img.mt-5(v-if="!dataImg")
              input.hidden(type="file" ref="mainUploadImgInput" @change="updateCurrImg" accept="image/*")
              .upload_image(@click="$refs.mainUploadImgInput.click()" vs-type='flex', vs-justify='flex-start', vs-align='flex-start' )

            // Image upload Buttons เส้นประ
            .modify-img.flex.justify-between.mt-3
              input.hidden(type="file" ref="updateImgInput" @change="updateCurrImg" accept="image/*")

</template>
<script>

// import moduleProductGroup from "@/store/product-group/moduleProductGroup.js";
import shapeFormat from "@/mixins/shapeFormat.js";

export default {
  props: {
    // list: Array,
    srcImage: {type:String},
    itemId: {type:String},
    imgpath: {type:String},
    addAPI : {type : String },
    getAPI : {type : String },
    removeAPI : {type : String },
    // view: Array,
    // viewform: Array,
    // init: Object
  },
  mixins: [shapeFormat],
  data() {
    return {
      inputImage: "",
      dataImg: "",
      thisImage: "",

      productGroup: {},
      images: []
    };
  },
  async created() {
        var resp = null;
        try{
             resp = await this.$http.post(
              this.$store.state.apiURL +
              this.getAPI ,{} ,
              this.$store.state.auth.apiHeader
            );
            if(resp.data === "failed")
            {
              this.inputImage = null;
            }else{
                  this.inputImage = resp.data;

            }

         } catch (err) {
          // this.noticeWarning("ยังไม่มีใส่รูปภาพในขณะนี้");
        }


  },

  watch: {
    // inputImage(){
    //   this.reloadImage();
    // }
  },
  methods: {
    removeItemImage() {
      this.$swal({
        title: "ต้องการลบรูปใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(async result => {
        if (result.value) {
          this.thisImage = null;
          this.dataImg = null;
          this.inputImage = null;
          try {

            // *** [003] REMOVE LOGO
            await this.$http.post(
              this.$store.state.apiURL +
                this.removeAPI ,
              { t1: this.productGroupId },
              this.$store.state.auth.apiHeader
            );
            this.inputImage = null;
          } catch (error) {
            this.$swal({
              icon: "error",
              title: "ไม่สามารถลบได้"
              // text: 'Something went wrong!',
            });
          }

          // this.deleteImage(imageid, indextr);
        }
      });
    },
    async updateCurrImg(input) {
      // this.$vs.loading({ type: "radius" });

      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);

        this.images.unshift(input.target.files[0]);

        // this.$store.dispatch("productList/addImage", input.target.files[0]);

      // *** [001] PARAMS : สำหรับ UPLOAD
        const formData = new FormData();
        formData.append("itemId", this.itemId);
        // formData.append("color", this.color);
        // formData.append("productItemId", this.productItemId);
        formData.append("file", input.target.files[0]);

        // *** [002] API PATH : API PATH สำหรับ UPLOAD
         var resp = null;
        try{
            resp = await this.$http.post(
              this.$store.state.apiURL +
              this.addAPI ,
              formData,
              this.$store.state.auth.apiHeader
            );
            this.noticeSuccess("เปลี่ยน Logo สำเร็จแล้ว");
            // this.inputImage = "shop_logo.png";
            this.inputImage = await resp.data;


         } catch (err) {
          this.noticeError("มีบางอย่างผิดพลาด");
        }



      }
    }

  },
  components: {

   }
};
</script>
<style>
</style>

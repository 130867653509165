<!-- =========================================================================================
	File Name: TableMiscellaneous.vue
	Description: Combine filter,sorter, pagination etc.
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Version: 1.1
	Author: Pixinvent
	Author URL: hhttp://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template lang="pug">
 vx-card
  // CREATE POP UP
  template
    vs-popup(classContent="popup-example label-font" title="เพิ่มข้อมูล" :active.sync="createBranchPopupActive")

      // ชื่อสาขา
      vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3") ชื่อสาขา
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7")
          vs-input.w-full(name="branchName" v-model="branchName")
      // ที่อยู่
      vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3") ที่อยู่
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7")
          vs-textarea.w-full(name="branchAddress" v-model="branchAddress" style="border: 1px #cccccc solid; height: 100px;")
      // เวลาทำการ
      vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3") เวลาทำการ
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7")
          vs-input.w-full(name="workingTime" v-model="workingTime")
      // เบอร์ติดต่อ
      vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3") เบอร์ติดต่อ
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="7" vs-xs="7")
          vs-input.w-full(name="branchPhone" v-model="branchPhone")
      vs-row(vs-w="12")
        vs-col(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12")
          vs-button(@click="saveBranch()" style="margin:10px;" color="success" type="filled") ตกลง
          vs-button(@click="closeCreateBranchPanel()" style="margin:10px;" color="danger" type="filled") ยกเลิก
    // POP UP
  // HEADER
  .grid-layout-container.alignment-block.label-font.my-container(style=" width:100%;")
    // BEGIN: RETURN DETAIL
    .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;")
      // -----------------------------------------------------------------
      // theline
      vs-row.row-form(vs-w="12" style="margin-top:10px;")
        vs-col(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6")
          h3(style="margin-margin: 20px;") ข้อมูลร้าน
            span.sm-btn-yellow(style="font-size: 0.8rem !important; cursor: pointer; margin-left: 10px; margin-top: -20px;" @click="$router.push('/backoffice/shop/settings')") ตั้งค่า
        vs-col(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="2")
        // SAVE ITEM
        vs-col(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="3")
        // SAVE ITEM
        // BACK to STOCK
        vs-col(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="2" vs-sm="2" vs-xs="2")
        // BACK to STOCK
      vs-row.row-form(vs-w="12" style="margin-top:20px;")
        vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="11" vs-sm="11" vs-xs="11")
          div(style="width:100%; height:5px; background-color:#50E3C2;")
    // -----------------------------------------------------------------
    // LOGO
    vs-row(vs-w='12')
      vs-col.px-6.typo__label(vs-type='flex', vs-justify='center', vs-align='center',vs-w='12' )
        AddMainImage( :key="imgCode" :srcImage="theImage" :itemId="shopinfo.shopInfoId" imgpath="" getAPI="/api/item-image-upload/get-shop-logo"  addAPI="/api/item-image-upload/upload-shop-logo" removeAPI="/api/item-image-upload/remove-shop-logo" )
    // ชื่อร้าน
    vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4") ชื่อร้าน
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6")
        vs-input.w-full(name="shopName" v-model="shopinfo.name")
    // เบอร์ติดต่อ
    vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4") เบอร์ติดต่อ
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6")
        vs-input.w-full(name="shopPhone" v-model="shopinfo.phone")
    // Line
    vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4") Line
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6")
        vs-input.w-full(name="shopLine" v-model="shopinfo.line")
    // Line
    vs-row.row-form(style="font-weight:bold; margin-top:30px;" vs-w="12")
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-end" vs-align="center" vs-lg="4" vs-sm="4" vs-xs="4")
        vs-row(vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="flex-start")
          div เงื่อนไขในใบเสร็จรับเงิน
      vs-col.form-label.px-2(vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="8" vs-sm="8" vs-xs="8")
        //- template
        //-   quill-editor(v-model='shopinfo.termAndCondition' )
        quill-editor(ref="myQuillEditor", v-model="shopinfo.termAndCondition")
          //- div(slot="toolbar")
          //-   // Add a bold button
          //-   button.ql-bold Bold
          //-   button.ql-italic Italic
          //-   // Add font size dropdown
          //-   select.ql-size
          //-     option(value="small")
          //-     // Note a missing, thus falsy value, is used to reset to default
          //-     option(selected)
          //-     option(value="large")
          //-     option(value="huge")
          //-   select.ql-font
          //-     option(selected="selected")
          //-     option(value="serif")
          //-     option(value="monospace")
          //-   // Add subscript and superscript buttons
          //-   button.ql-script(value="sub")
          //-   button.ql-script(value="super")
        //- tiptap-editor.w-full(name="shopLine" v-model="shopinfo.termAndCondition" :key="edikey")
        br
        div(style="font-size:11px; margin-top:1rem; color: #4a90e2;") (เหลือความยาวตัวอักรษร {{remainingText(1200,shopinfo.termAndCondition)}} )
  // -----------------------------------------------------------------
  div(style="border-top: 1px solid #E3E3E3; margin-bottom:40px; ")
  // -----------------------------------------------------------------
  // ปุ่ม
  vs-row(vs-w="12" vs-type="flex" vs-justify="center")
    vs-button(@click="updateinfo()" color="success" type="filled") บันทึกข้อมูล
  // -----------------------------------------------------------------
  // ------------------------ SHOP LIST ------------------------------
  // -----------------------------------------------------------------
  div(style="margin-top: 50px;")
  template
    shop-list(apiname="branch" idname="BranchId" labelname="รายการสาขา")

</template>


<script>

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'


import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";

import CurrencyValue from "./Components/CurrencyValue";
import shapeFormat from "../../mixins/shapeFormat.js";
import AddMainImage from "@/views/wintershopback/Components/AddMainImage.vue";
import TiptapEditor from "./Tiptap";
import ShopList from "./Components/ShopList.vue";
import { setTimeout } from "timers";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  data() {
    return {
      edikey: 0,
      blockText: null,
      previousText: null,
      editorOption: {
        modules: {
          toolbar: '#toolbar'
        }
      },
      theImage : "",
      imgCode: 0,
      createBranchPopupActive: false,

      shopName: "Alex Rental Platform",
      shopAddress: "รัชดาภิเษก 32 ",
      shopPhone: "0979189838",
      shopLine: "@alexrental.app",
      branchName: "",
      branchAddress: "",
      workingTime: "",
      branchPhone: "",
      termandcondition: "",
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      }
    };
  },

  created: function() {

    this.$http
      .get(
        this.$store.state.apiURL + "/api/branch",
        this.$store.state.auth.apiHeader
      )
      .then(response => {
        //  console.log(response.status);
        if (response.status === 200) {
          this.branches = response.data;
        }
      });


  },
  components: {
    Prism,
    Datepicker,
    CurrencyValue,
    ShopList,
    TiptapEditor,
    AddMainImage,
    quillEditor
  },
  async mounted() {
    var res = await this.reload();
    if (res !== null) {
      this.shopinfo = await res.data;
      // console.log('shopinfo.termAndCondition LENGTH >>> ',this.shopinfo.termAndCondition.length);
      // console.log('test content LENGTH >>> ',this.testcontent.length);

    }

  },
  computed: {
    editor() {
        return this.$refs.myQuillEditor.quill
      },
  //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

      return this.$store.getters["auth/currentPackage"];
    },
    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    }
  },
  methods: {
    onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
        console.log('editor change!', quill, html, text)
        this.content = html
      },
    remainingText(maxText, theText){
      if(theText === undefined || theText === null) theText = "";
      this.blockText = theText;
      this.previousText
      var t = maxText - theText.length;
      if(t <= 0) return 0;
      else
        return t;
    },
    theLogo() {

      if (
        this.shopinfo.logo === undefined ||
        this.shopinfo.logo === "" ||
        this.shopinfo.logo === null
      ) {
        return this.$store.state.defaultStore + "logo.png";
      } else {
        return this.$store.state.defaultStore  + this.shopinfo.logo;
      }
    },
    async reload() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res;
    },

    async updateinfo() {
      this.$vs.loading({ type: "radius" });
      var res = null;
          // console.log('shopinfo.termAndCondition LENGTH >>> ',this.shopinfo.termAndCondition.length);

      try {
        res = await this.$http.put(
          this.$store.state.apiURL +
            "/api/shopinfo/" +
            this.shopinfo.shopInfoId,
          this.shopinfo,
          this.$store.state.auth.apiHeader
        );

      } catch (err) {
        res = null;
      }

      if (res !== null) {
        setTimeout(() => {
          this.noticeSuccess("บันทึกสำเร็จ");
          this.$vs.loading.close();
        }, 1000);
      } else {
        setTimeout(() => {
          this.noticeError("บันทึกไม่สำเร็จ");
          this.$vs.loading.close();
        }, 1000);
      }
    },

    saveBranch() {
      this.$http
        .post(
          this.$store.state.apiURL + "/api/branch",
          {
            branchName: this.branchName,
            address: this.branchAddress,
            workingTime: this.workingTime,
            phone: this.branchPhone,
            description: "",
            state: true

            // console.log("response data >>> ", response.data);
          },
          this.$store.state.auth.apiHeader
        )
        .then(response => {
          // console.log(response.status);
          if (response.status === 200) {
            this.$vs.notify({
              title: "บันทึกเรียบร้อยแล้ว",
              text: "บันทึกข้อมูลหน้าร้านเรียบร้อยแล้ว",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-center",
              color: "success"
            });
            this.closeCreateBranchPanel();
            // this.$router.push("/backoffice/create-item/" + response.data);
          } else {
            this.$vs.notify({
              title: "เกิดข้อผิดพลาด",
              text: "ไม่สามารถสร้างสินค้าใหม่ได้",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-center",
              color: "danger"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-center",
            color: "danger"
          });
        });
    },
    openCreateBranchPanel() {
      this.createBranchPopupActive = true;
      this.resetInfo();
    },
    closeCreateBranchPanel() {
      this.createBranchPopupActive = false;
      this.resetInfo();
    },

    saveInfo() {
      this.$vs.notify({
        title: "บันทึกเรียบร้อยแล้ว",
        position: "top-center",
        text: "บันทึกเรียบร้อยแล้ว",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
    },
    resetInfo() {
      this.branchName = "";
      this.branchAddress = "";
      this.workingTime = "";
      this.branchPhone = "";
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      // console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  watch: {
    blockText : function(newvalue , oldvalue){
      var remText = 1200 - newvalue.length;
      if(remText < 0){
        this.shopinfo.termAndCondition = oldvalue;
        this.$swal({
          icon: 'warning',
          title: 'ความยาวตัวอักษรเกินกว่าที่กำหนด',
        });
        this.edikey++;

      }


    },

    addtocart: function(newvalue) {
      var newItem = this.itemsInStock.find(function(ele) {
        if (ele.itemName === newvalue) {
          return ele;
        }
      });
      if (newItem) {
        event.target.setSelectionRange(0, this.addtocart.length);

        var foundindex = this.displayItems.findIndex(
          x => x.itemName === newItem.itemName
        );

        if (foundindex < 0) this.displayItems.push(newItem);
        else {
          this.displayItems[foundindex].reservedQuantity++;
        }

        // setTimeout(function() {
        //   this.addtocart = "";
        // }, 2000);

        this.addtocart = "";
      }
    },
    theImage(){
      this.imgCode = this.imgCode + 1;
    },

    orders: {
      handler() {
        this.rentalPriceTotal = 0;
        this.bailTotal = 0;
        this.grandTotal = 0;

        for (var i = 0; i < this.orders.length; i++) {
          this.rentalPriceTotal =
            this.rentalPriceTotal +
            this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

          this.bailTotal =
            this.bailTotal +
            this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
        }
        this.grandTotal =
          this.grandTotal + this.rentalPriceTotal + this.bailTotal;

        // console.log("this.grandTotal >>> ", this.grandTotal);
      },
      deep: true
    }
  }
};
</script>

<style>

.ProseMirror p{
  height: 100% !important;
}

input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.label-font {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}
.normal-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}
.grand-total-discount {
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
}
.table-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}

.the-black {
  color: #494949;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.my-container {
  margin-top: 30px !important;
}
.little-comment {
  font-size: 14px;
  font-family: "SukhumvitSet-Medium";
}
.padding-left-30 {
  padding-left: 30px;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.ck-file-dialog-button {
  display: none;
}

.menubar__button {
  height: 30px;
  padding: 5px 5px !important;
  width: 40px !important;
  background-color: white !important;
  border-radius: 7px;
  cursor: pointer;
  font-size: 11px;
  margin-right: 5px;
}

.editor__content {
  width: 100%;
  margin-top: 20px;
  min-height: 200px;
  border-radius: 7px;
  border: 1px solid #4c4c4c;
  font-weight: normal !important;
}

.ql-link{
  display: none !important;
}
.ql-image{
  display: none !important;
}
.ql-link{
  display: none !important;
}
.ql-clean{
  display: none !important;
}

.ql-picker{
  display: none !important;
}

.ql-video{
  display: none !important;
}

.ql-direction{
  display: none !important;
}

.ql-script{
  display: none !important;
}

.ql-code-block{
  display: none !important;
}

</style>
